import React from 'react';
import PropTypes from 'prop-types';

import Loader from '@components/elements/Loader';

const Loadable = ({ loading, children }) => (loading ? <Loader /> : children);

Loadable.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Loadable;
