import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import formatSsn from '@utils/formatter/formatSsn';

import Input from './Input';

const SocialSecurityNumberInput = ({
  onChange,
  value,
  hasError,
  isSuccess,
}) => {
  const formatOnChange = useCallback((key, ssnRowValue) => {
    const ssn = ssnRowValue.replace(/[^0-9]/g, '').substr(0, 12);
    onChange(key, ssn);
  }, [onChange]);

  return (
    <Input
      _id="socialSecurityNumber"
      type="tel"
      label="Personnummer"
      placeholder="ååååmmdd-xxxx"
      value={formatSsn(value)}
      hasError={hasError}
      isSuccess={isSuccess}
      onChange={formatOnChange}
    />
  );
};

SocialSecurityNumberInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

SocialSecurityNumberInput.defaultProps = {
  hasError: null,
  isSuccess: null,
};

export default SocialSecurityNumberInput;
