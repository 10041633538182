import React from 'react';
import PropTypes from 'prop-types';

import SectionStyled, { SectionContentStyled, FullSizeTitleContentStyled } from '@components/containers/SectionStyled';

const Section = ({
  id,
  children,
  background,
  color,
  colorLaptop,
  image,
  imageTablet,
  imageLaptop,
  narrow,
  medium,
  arrow,
  overlapSpace,
  overlapColour,
  fullWidth,
  noBottomPadding,
  noTopPadding,
  isFullSizeTitleSection,
}) => (
  <SectionStyled
    id={id}
    background={background}
    color={color}
    colorLaptop={colorLaptop}
    image={image}
    imageTablet={imageTablet}
    imageLaptop={imageLaptop}
    arrow={arrow}
    overlapSpace={overlapSpace}
    overlapColour={overlapColour}
  >
    {isFullSizeTitleSection ? (
      <FullSizeTitleContentStyled>
        {children}
      </FullSizeTitleContentStyled>
    ) : (
      <SectionContentStyled
        narrow={narrow}
        medium={medium}
        fullWidth={fullWidth}
        overlapSpace={overlapSpace}
        overlapColour={overlapColour}
        noBottomPadding={noBottomPadding}
        noTopPadding={noTopPadding}
      >
        {children}
      </SectionContentStyled>
    )}
  </SectionStyled>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  background: PropTypes.bool,
  arrow: PropTypes.bool,
  color({ color, image, background }, _, componentName) {
    return ((!!color && !!image) || (!!color && !!background))
      ? new Error(`A Section cannot has color and others background props. Change the props of ${componentName}`)
      : null;
  },
  image({ color, image, background }, _, componentName) {
    return ((!!color && !!image) || (!!image && !!background))
      ? new Error(`A Section cannot has image and others background props. Change the props of ${componentName}`)
      : null;
  },
  imageTablet: PropTypes.node,
  imageLaptop: PropTypes.node,
  colorLaptop({ imageLaptop, colorLaptop }, _, componentName) {
    return (!!imageLaptop && !!colorLaptop)
      ? new Error(`A Section cannot has imageLaptop and colorLaptop props at the same time. Change the props of ${componentName}`)
      : null;
  },
  narrow: PropTypes.bool,
  medium({ narrow, medium }, _, componentName) {
    return ((!!narrow && !!medium))
      ? new Error(`A SectionContent cannot has medium and other size props at the same moment. Change the props of ${componentName}`)
      : null;
  },
  overlapSpace: PropTypes.string,
  overlapColour: PropTypes.string,
  fullWidth: PropTypes.bool,
  noBottomPadding: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  isFullSizeTitleSection: PropTypes.bool,
};

Section.defaultProps = {
  id: null,
  background: false,
  arrow: false,
  color: null,
  colorLaptop: null,
  image: null,
  imageTablet: null,
  imageLaptop: null,
  narrow: false,
  medium: false,
  overlapSpace: null,
  overlapColour: null,
  fullWidth: false,
  noBottomPadding: false,
  noTopPadding: false,
  isFullSizeTitleSection: false,
};

export default Section;
