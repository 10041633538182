import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showModal } from '@redux/actions/ui';
import { setSocialSecurityNumber } from '@redux/actions/user';
import modalTypes from '@components/modals/types';
import { bankIdRequests } from '@constants';
import validators from '@utils/validators';
import Button from '@components/elements/Button';
import Page from '@components/containers/Page';
import SocialSecurityNumberInput from '@components/elements/SocialSecurityNumberInput';
import Section from '@components/containers/Section';
import FlexBox from '@components/containers/FlexBox';
import Loadable from '@components/containers/Loadable';

const Login = () => {
  const dispatch = useDispatch();
  const socialSecurityNumber = useSelector((state) => state.user.socialSecurityNumber);
  const loading = useSelector((state) => state.accounts.loading);

  const isValid = useCallback(() => {
    if (!socialSecurityNumber || !socialSecurityNumber.length) return null;
    return validators.socialSecurityNumber(socialSecurityNumber);
  }, [socialSecurityNumber]);

  const onChange = useCallback((_, value) => {
    dispatch(setSocialSecurityNumber(value));
  }, [dispatch]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    if (!isValid()) return;

    dispatch(showModal(
      {
        type: modalTypes.BANK_ID,
        props: {
          request: bankIdRequests.LOGIN,
          payload: { socialSecurityNumber },
        },
      },
    ));
  }, [dispatch, isValid, socialSecurityNumber]);

  return (
    <Page noLinks>
      <Section narrow>
        <Loadable loading={loading}>
          <FlexBox
            flexDirection="column"
            alignItems="center"
          >
            <SocialSecurityNumberInput
              value={socialSecurityNumber}
              hasError={isValid() === false}
              isSuccess={isValid()}
              onChange={onChange}
            />
            <Button
              disabled={!isValid()}
              onClick={onSubmit}
            >
              Logga in
            </Button>
          </FlexBox>
        </Loadable>
      </Section>
    </Page>
  );
};

export default Login;
