const formatSsn = (ssn) => {
  let formattedSsn = ssn;
  if (formattedSsn) {
    formattedSsn = `${formattedSsn.substr(0, 8)}-${formattedSsn.substr(8)}`;
    while (formattedSsn.endsWith('-')) { formattedSsn = formattedSsn.substr(0, formattedSsn.length - 1); }
  }
  return formattedSsn;
};

export default formatSsn;
