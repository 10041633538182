import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import style from '@constants/style';
import match from '../../utils/functional/match';

const DEFAULT = '60rem';
const NARROW = '30rem';
const MEDIUM = '45rem';

const getMaxWidth = (props) => match(props)
  .on(({ fullWidth }) => fullWidth, () => 'none')
  .on(({ narrow }) => !!narrow, () => NARROW)
  .on(({ medium }) => !!medium, () => MEDIUM)
  .otherwise(() => DEFAULT);

export const SectionContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;

  max-width: ${(props) => getMaxWidth(props)};

  padding-left: ${style.padding.regular};
  padding-right: ${style.padding.regular};
  padding-top: ${({ noTopPadding }) => (noTopPadding ? '0' : style.padding.big)};
  padding-bottom: ${({ overlapSpace }) => (overlapSpace ? '0' : style.padding.big)};
  width: calc(100% - ${style.padding.big});

  ${style.media.tablet`
    padding-left: ${({ fullWidth }) => (fullWidth ? '12rem' : style.padding.huge)};
    padding-right: ${({ fullWidth }) => (fullWidth ? '12rem' : style.padding.huge)};
    padding-top: ${({ noTopPadding }) => (noTopPadding ? '0' : style.padding.huge)};
    padding-bottom: ${({ overlapSpace }) => (overlapSpace ? '0' : style.padding.huge)};
    width: calc(100% - ${({ fullWidth }) => (fullWidth ? '24rem' : style.padding.godlike)});
  `}

  ${style.media.laptop`
    padding-left: ${({ fullWidth }) => (fullWidth ? '0' : style.padding.huge)};
    padding-right: ${({ fullWidth }) => (fullWidth ? '0' : style.padding.huge)};
    padding-top: ${({ noTopPadding }) => (noTopPadding ? '0' : style.padding.gigantic)};
    padding-bottom: ${({ noBottomPadding }) => (noBottomPadding ? '0' : style.padding.gigantic)};
    width: calc(100% - ${({ fullWidth }) => (fullWidth ? '0%' : style.padding.godlike)});
  `}

  ${style.media.desktop`
    padding-left: ${({ fullWidth }) => (fullWidth ? '0' : style.padding.godlike)};
    padding-right: ${({ fullWidth }) => (fullWidth ? '0' : style.padding.godlike)};
    width: calc(100% - ${({ fullWidth }) => (fullWidth ? '0%' : '12rem')});
  `}

  ${style.overlappingMixin}
`;

SectionContentStyled.propTypes = {
  noBottomPadding: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  narrow: PropTypes.bool,
  medium: PropTypes.bool,
};

SectionContentStyled.defaultProps = {
  noBottomPadding: false,
  noTopPadding: false,
  narrow: false,
  medium: false,
};

export const FullSizeTitleContentStyled = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  padding: ${style.padding.medium} 0;

  ${style.media.laptop`
    padding: ${style.padding.big} 0;
  `}
`;

const TRIANGLE_WIDTH = '2.5rem';
const TRIANGLE_HEIGHT = '2rem';

const SectionStyled = styled('section')`
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: row;
  padding: 0;
  position: relative;

  background-position: right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;

  ${(props) => match(props)
    .on(({ image }) => !!image, ({ image }) => css`background-image: url(${image});`)
    .on(({ color }) => !!color, ({ color }) => css`background-color: ${color};`)
    .on(({ background }) => !!background, () => css`background-color: ${style.colours.secondaryLightGray2};`)
    .otherwise(() => '')}


  ${style.media.tablet`
    ${({ imageTablet }) => (imageTablet ? css`background-image: url(${imageTablet});` : '')}
  `}

  ${style.media.laptop`
    ${(props) => match(props)
    .on(({ imageLaptop }) => !!imageLaptop, ({ imageLaptop }) => css`background-image: url(${imageLaptop});`)
    .on(({ colorLaptop }) => !!colorLaptop, ({ colorLaptop }) => css`background-color: ${colorLaptop};`)
    .otherwise(() => '')}
  `}

  ${({ arrow, color }) => (arrow ? `
    &::after{
      content:'';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -${TRIANGLE_WIDTH};
      width: 0;
      height: 0;
      border-top: solid ${TRIANGLE_HEIGHT} ${color || style.colours.secondaryLightGray2};
      border-left: solid ${TRIANGLE_WIDTH} transparent;
      border-right: solid ${TRIANGLE_WIDTH} transparent;
    }
  ` : '')}

  ${style.overlappedMixin}
`;

SectionStyled.propTypes = {
  background: PropTypes.bool,
  arrow: PropTypes.bool,
  color: PropTypes.node,
  image: PropTypes.node,
  imageTablet: PropTypes.node,
  imageLaptop: PropTypes.node,
};

SectionStyled.defaultProps = {
  background: false,
  arrow: false,
  color: null,
  image: null,
  imageTablet: null,
  imageLaptop: null,
};

export default SectionStyled;
